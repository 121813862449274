<template>
  <div>
    <h2>{{ news.title }}</h2>
    <p>{{ news.content }}</p>
    <img :src="news.main_image" alt="news image">
  </div>
</template>

<script>
export default {
  name:"NewsDetail",
  data() {
    return {
      news: {}
    }
  },
  methods: {
    loadNews() {
      const id = this.$route.params.id;
      fetch(`/api/news/${id}/`)
        .then(response => response.json())
        .then(data => {
          this.news = data;
        })
    },
  },
  mounted() {
    this.loadNews();
  }
}
</script>

<template>
  <div class="mycontainer1">


    <div class="hellotxt-wrap">
      <div class="hellotxt" lang="en">Say "hello" to</div>
    </div>
    <div>
      <img id="cl_img" class="animate__animated animate__fadeInUp"
           src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/jbzm.png" alt="">
      <div id="logo-app-wrap">
        <img id="logo-app" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="">
      </div>
    </div>
    <div class="animate__animated animate__fadeInUp  intro-texts-areas">

      <div class="intro-des">
        宁波索迈视听科技有限公司是一家集设计研发、生产销售综合一体的国家高新技术企业，
        18年来已成长为全球市场的优质品牌运营商，并在品牌经济时代的崛起路上，跻身中国出海品牌的领先阵营。
      </div>
    </div>
    <div class="intro_points animate__animated animate__fadeInUp ">
      <div class="intro_point">
        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/40b.png" alt="">
        <div>产品研发人员占比</div>
      </div>
      <div class="intro_point">
        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/15j.png" alt="">
        <div>业务遍布的国家数量</div>
      </div>
      <div class="intro_point">
        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/500w.png" alt="">
        <div>成立至今的用户购买量</div>
      </div>
    </div>
  </div>


  <div class="mycontainer1 container-with-bg1" id="video-containter">
    <video controls muted width="100%" height="100%" style="object-fit: cover;" id="intro-video"
           poster="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/videocover.jpg">
      <source src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/sample.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>



  <div class="mycontainer1">
    <div class="intro-dev">
      <img class="intro-des-img-mb" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb_dev_hs.png" alt="索迈发展历程">
      <img class="intro-des-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-intro-screen.png"  alt="索迈发展历程">
    </div>

  </div>


<!--  <div class="mycontainer1" id="cus-gri-dev">-->
<!--    <div class="year-container">-->
<!--      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/year-pc-white.png" alt="Bottom Image" class="year-image">-->
<!--      <div class="year-mask"></div> &lt;!&ndash; 遮罩，用于揭露上层图片 &ndash;&gt;-->
<!--      <div class="year-arrow">-->
<!--        <img width="50px" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/cyc-bike.png">-->
<!--      </div> &lt;!&ndash; 箭头元素 &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="mycontainer1 container-with-bg2">-->
<!--    <div class="left-areas-logo">-->
<!--      <div class="logo-para-div">-->
<!--        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="white logo">-->
<!--      </div>-->


<!--    </div>-->
<!--    <div class="right-areas-texts">-->
<!--      <div class="right-areas-text-top">-->
<!--        <div class="intro-title">Company Introduction</div>-->
<!--        <div class="intro-sub-title">企业简介</div>-->
<!--        <div class="cus-line"></div>-->
<!--        <div class="intro-sub-contents">-->
<!--          <p>宁波索迈视听科技有限公司成立于2005年11月，经过18年来的探索与成长，已成为一家集设计、研发、生产、销售综合一体的国家高新技术企业。</p>-->
<!--          <p>-->
<!--            2015年，公司深入贯彻落实“科技兴贸”战略，以科技创新为核心，积极推进外贸发展方式转变，在自有生产基地的优势基础上大力发展跨境电商及国内市场，并于北美、欧洲等地设立多处海外仓，产品畅销三十多个国家，遍布亚、欧、北美、南美、大洋各大洲。</p>-->
<!--          <p>2017年以来，索迈陆续与众多国际知名采购商和世界零售巨头建立合作伙伴关系，开拓并布局广泛的全球渠道网络，公司产品深受各国消费者的喜爱和认同，全球粉丝量已累计过亿。</p>-->
<!--          <p>2019年为巩固跨境电商业务的品牌化发展，不断完善企业自身核心竞争力，索迈加强企业自身建设，扩充面积4万多平方米生产基地，引进国外先进生产设备，为业务提升赋能，同年索迈摘得“中国亚马逊品牌奖”殊荣。</p>-->
<!--          <p>-->
<!--            2021年开始，企业注重规模化增长，花下重金增加百万培训投入，在企业管理、高绩效增长、员工成长、企业培训及品牌发展等多方面，进行大胆革新，引入行业领先ERP管理、阿米巴机制、实效增长方法论等创新管理模式和工具，重修内功，进一步夯实品牌出海的战略决心。</p>-->
<!--          <p>-->
<!--            2023年作为全面品牌化发展的启动元年，索迈坚持与时间做朋友，旨在促进企业生存发展的持久续航。公司旗下已拥有FITUEYES、WAMPAT、FENGE三个独立发展品牌，主营业务覆盖视听支架、时尚创意家居及人机工学桌椅三大类目，索迈始终坚持以“产品品质”为战略基石、“用户第一”为价值导向、“创新自驱”为增长飞轮，为全球用户创造创新、时尚、健康的家居体验。</p>-->
<!--          <p>-->
<!--            未来，索迈将始终“用我们的创意惠及全球”为企业伟大使命，以“全球创意家居引领者”为事业愿景，深入践行“用户第一、阳光健康、由我先行、共赢共享”的企业价值观，持续创造全球用户的真实价值，力争成为无愧于伟大新时代的好企业、好品牌。</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="right-areas-text-bottom">-->
<!--        <div class="intro-title">Development History</div>-->
<!--        <div class="intro-sub-title">发展历程</div>-->
<!--        <div class="cus-line"></div>-->
<!--        <div class="intro-sub-contents">-->
<!--          <div class="intro-dtl-mob">-->
<!--            <p>1994 - 2005 从思迈到索迈</p>-->
<!--            <p>94年创立思迈（英语“SMILE”谐音），主营国内广告材料的生产和销售，为索迈的建立提供了第一笔保障。</p>-->

<!--          </div>-->
<!--          <div class="intro-dtl-mob">-->
<!--            <p>2005 - 2015 B2B转型B2C</p>-->
<!--            <p>05年索迈成立后，追随宁波商业大潮的外贸业务，从电视柜贴牌加工到进入ODM阶段，期间以产品创新为核心驱动力，自主研发并设计首款"SILENT"电视柜集成音响，获得宁波市“和丰奖”工业设计大赛金奖。</p>-->
<!--          </div>-->
<!--          <div class="intro-dtl-mob">-->
<!--            <p>2015 - 至今 开启品牌之路</p>-->
<!--            <p>-->
<!--              2015年是思变的一年，公司创始团队意识到无论是做贴牌加工还是传统外贸，永远是在被动迎合中间商的需求，订单不确定化，无法建立自己的品牌形象，在调研了国外市场及国内优秀公司学习之后，决心打造自己的品牌，并转型做跨境电商，索迈走上了跨境电商品牌出海之路。</p>-->
<!--          </div>-->
<!--        </div>-->


<!--      </div>-->


<!--    </div>-->

<!--  </div>-->
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';

export default {

  name: "AppIntroduction",
  data() {
    return {};
  },


  mounted() {
    document.title = '关于我们|SOMLE'
    if (window.innerWidth >= 1030) {
      this.executeAnLargeScreen();
    } else {
      this.executeAnSmallScreen();
    }
  },
  methods: {
    executeAnLargeScreen() {

      gsap.registerPlugin(ScrollTrigger)
      //
      //
      // let t1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: "#cus-gri-dev",
      //     start: "top top",
      //     end: "bottom center",
      //     pin: true,
      //     // scrub: 1,
      //     reverse: false,
      //   }
      // })
      //
      //
      // t1.to(".year-mask", {
      //
      //   width: '100%',
      //   duration: 3,
      //   ease: "power2.out"
      // });
      //
      // t1.to(".year-arrow", {
      //
      //   left: '98%',
      //   duration: 3,
      //   ease: "power2.out"
      // }, 0);
      //


      const videoElement = document.querySelector('#intro-video');

      // 使用GSAP来创建动画
      gsap.fromTo(videoElement,
          {scale: 0.6}, // 开始状态
          {
            scale: 1.0, // 结束状态
            duration: 1, // 动画持续时间
            scrollTrigger: {
              trigger: "#video-containter",
              start: "top top", // 当视频容器的顶部到达视窗的中部时开始动画
              end: "+=500",
              pin: true,
              // markers: true, // 仅用于调试，显示触发位置
              toggleActions: "play none none reverse" // 在滚动时决定动画如何播放
            }
          }
      );

    },
    executeAnSmallScreen() {
      gsap.registerPlugin(ScrollTrigger)

      // 找到你的视频元素
      const videoElement = document.querySelector('#intro-video');

      // 使用GSAP来创建动画
      gsap.fromTo(videoElement,
          {scale: 0.8}, // 开始状态
          {
            scale: 1.0, // 结束状态
            duration: 1, // 动画持续时间
            scrollTrigger: {
              trigger: "#video-containter",
              start: "top top", // 当视频容器的顶部到达视窗的中部时开始动画
              end: "+=500",
              pin: true,
              // markers: true, // 仅用于调试，显示触发位置
              toggleActions: "play none none reverse" // 在滚动时决定动画如何播放
            }
          }
      );


    }

  }

}
</script>

<style scoped>
.intro-dev{

}
.intro-des-img-mb{
  display: none;
}
.intro-des-img{
  width: 95%;
}

.gri-pc-nu-main{
  position: absolute;
  top: 6vh;
  left: 32vw;
}

.gri-pc-nu-main img{
  width: 32vw;
  height: 16vw;

}

.gri-dev-timeline{
    transform: rotate(256deg);
    width: 4vw;
    height: 87vw;
    position: absolute;
    top: -40vh;
    left: 45vw;
}


.cus-gri-dev-texts{
    display: flex;
    width: 91vw;
    position: absolute;
    top: 62vh;
}


/*------------------backup plan a s--------------------------------*/
.year-container {
  position: relative;
  width: 100%;
  max-width: 75%;
  margin: auto;
}

.year-image, .year-mask {
  width: 100%;
  display: block; /* 避免图片下方的空白 */
}

.year-mask {
  position: absolute;
  top: 0;
  left: 0;
  background: url('https://offsitesm.oss-cn-shanghai.aliyuncs.com/year-pc-gri.png') no-repeat; /* 上层图片作为背景 */
  background-size: cover;
  width: 0%; /* 初始宽度为0 */
  height: 100%;
}

.year-arrow {
  position: absolute;
  top: 65%; /* 将箭头置于容器的垂直中间 */
  left: 0; /* 初始位置在容器的最左侧 */
  transform: translateY(-50%); /* 垂直居中 */
  font-size: 24px; /* 箭头大小，可根据需要调整 */
  color: white; /* 箭头颜色 */
  pointer-events: none; /* 防止箭头影响点击事件 */
}
/*---------------------backup plan a e-----------------------------*/




















html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.intro-sub-contents br {
  margin-top: 15vh;
}

#dev-his-main {
  background: black;
}

.mycontainer1 {
  /* width: 99vw;
  height: 99vh; */

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: cover;
  background-color: #101010;
  color: #A6A6A6;
  /* padding: 1vw; */
  justify-content: space-between;
  text-align: center;
  /* overflow: hidden; */

}


.intro_points {
  display: flex;
}

.intro_point {
  padding: 5rem 9rem 5rem 9rem;
}

.hellotxt-wrap {
  position: relative;
  width: 30vw;
  /* change this to fit your needs */
  height: 18vh;
  /* change this to fit your needs */
  overflow: hidden;
}

.hellotxt {
  padding-top: 6vh;
  /*margin-top: 6vh;*/
  font-size: 1.8vw;
  position: absolute;
  left: 0;
  right: 0;
  animation: reveal 3s forwards;
  width: 100%;
  height: 100%;
  clip-path: inset(0 100% 0 0);
}


#logo-app {
  position: absolute;
  width: 100%;
  top: 40vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 8vw;
  /* height: 100px; */
  animation: reveal 3s forwards;
  clip-path: inset(0 100% 0 0);
}

#cl_img {
  height: 33vh;
  position: absolute;
  top: 22vh;
  left: 44vw;
  transform: translate(-50%, -50%);
  width: 12vw;


  /*width: 100%;*/
}

.intro_point img {

  height: 5vw;
  object-fit: cover;
}


.video-container {
  /* width: calc(170 * 8px);
  height: calc(90 * 8px); */
  width: 645px;
  height: 670px;
  /*background: url('@/assets/imgs/mob-side.png') no-repeat center;*/
  background-size: contain;
  /* This will make sure the background image fits within the container */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
  /* This will allow you to center the video within the container */
}

.video-container video {

  overflow: hidden;
  position: absolute;
  /* This will allow you to center the video within the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81%;
  /* This will prevent the video from covering the border of the background image. Adjust this value as necessary. */
  height: 75%;

  /* This will prevent the video from covering the border of the background image. Adjust this value as necessary. */
}


@keyframes reveal {
  to {
    clip-path: inset(0 0 0 0);
  }
}

.container-with-bg1 {
  background: url(https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/bg-cl.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.container-with-bg2 {
  background: url(https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/hs-bg.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
}

.left-areas-logo {
  flex-basis: 50.33%;
  margin-bottom: -41%;
  /* margin-top:53% */
}

.logo-para-div {
  text-align: left;
  padding-left: 5rem;
}

.left-areas-logo img {
  width: 10vw;
}

.right-areas-texts {
  width: 40vw;
  margin-right: 8vw;
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.right-areas-text-top {
  color: white;

  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: hsla(0, 0%, 100%, .23);
  border-radius: 3rem;
  padding: 2vw;

}

.right-areas-text-top::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -30px;
  z-index: -2;
  -webkit-filter: blur(20px);
  filter: blur(15px);
  opacity: 0.9;
}


.intro-sub-contents {
  line-height: 1.8;
  overflow: overlay;

}

.right-areas-text-bottom {
  border-radius: 3rem;
  padding: 2vw;

  margin-top: 1rem;
  color: white;
  background: hsla(0, 0%, 100%, .23);
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  backdrop-filter: blur(8px);
}

.right-areas-text-bottom::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -30px;
  z-index: -2;
  -webkit-filter: blur(20px);
  filter: blur(15px);
  opacity: 0.9;
}


.intro-title {
  font-size: 2.0rem;
}

.intro-sub-title {
  margin-top: 0.5rem;
  font-size: 1.3rem;
}

.intro-sub-contents {
  font-size: 0.8vw;
  text-align: left;
}

.intro-dtl-mob > p:first-of-type {
  font-weight: bold;
}

.cus-line {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  width: 100%;
  height: 0.1vh;
  /* 直线的高度 */
  background-color: white;
  /* 直线的颜色 */
}

.intro-des {
  padding-top: 45vh;
}

.intro-texts-areas {
  width: 55%;
  font-size: 1.2vw;
}


@keyframes myAnimation {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(90deg) scale(1);
  }

  100% {
    transform: rotate(90deg) scale(1.5);
  }
}

.animated {
  animation: myAnimation 2s forwards;
}

/* 隐藏默认滚动条 */
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 153, 77, .2);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 153, 77, .2);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 153, 77, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 153, 153, .4);
}

::-webkit-scrollbar-track:active {
  background-color: rgba(0, 153, 77, .5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 153, 153, .1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 153, 77, .3);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 153, 153, .6);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 153, 153, .8);
}

@media (min-width: 200px) and (max-width: 1030px) {
  .intro-des-img-mb{
    margin-top: 5%;
    margin-left: 3%;
    display: block;
    width: 94%;
  }
    .intro-des-img{
    display: none;

  }
  .intro-dtl-mob {
    font-size: 2.8vw;
  }

  .intro-dtl-mob > p:first-of-type {
    font-weight: bold;
    font-size: 3.2vw;

  }


  .intro-des {
    padding-top: 2vh;
    font-size: 3vw;
    margin-top: 45vh;
  }

  .intro-sub-title {
    font-size: 4vw;
  }

  .hellotxt {
    padding-top: 10vh;
    font-size: 4vw;
  }

  #intro-video {
    width: 100%;
    height: 30vh; /* 视口的30% */
  }

  .intro-texts-areas {
    width: 80%;
    /*font-size: 1.2vw;*/
  }

  #logo-app {
    top: 40vh;
    width: 28vw;
  }

  #cl_img {
    width: 43vw;
    left: 28vw;
  }

  .intro_points {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .intro_point {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 2.5rem;
    padding-top: 0.8rem;
  }

  .intro_point img {
    height: 9vw;
  }


  .hellotxt-wrap {
    height: 140px;
  }

  .container-with-bg2 {
    flex-direction: column;
  }

  .right-areas-texts {
    width: 90%;
  }

  .right-areas-texts {
    margin-left: 0;
    margin-right: 0;
  }

  .logo-para-div {
    padding-left: 0;
    padding-top: 10vh;
    padding-bottom: 3vh;
  }

  .left-areas-logo {
    margin-bottom: 0;

  }

  .left-areas-logo img {
    width: 28vw;
  }

  .intro-title {
    font-size: 1rem;
  }

  .cus-line {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }

  .right-areas-text-top {
    padding: 1.5rem;
  }

  .right-areas-text-bottom {
    padding: 1.5rem;
  }

  .right-areas-text-top {
    height: 42%;
  }

  .right-areas-text-bottom {
    height: 42%;
  }

  .intro-sub-contents {
    font-size: 2.8vw;
  }

  .left-areas-logo {
    flex-basis: auto;
  }

}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'animate.css/animate.css'
import router from './router'
import 'bootstrap'




import Parallax from 'parallax-js'

const app = createApp(App)


app.mixin({
  methods: {
    createParallax(refName) {
      return new Parallax(this.$refs[refName])
    },
    destroyParallax(parallaxInstance) {
      if (parallaxInstance) {
        parallaxInstance.disable()
      }
    },
  }
})


createApp(App)
  .use(router)
  .mount('#app')


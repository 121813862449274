<template>
  <div class="mycontainer1 cus-con">
    <div class="figure-pare">
      <!-- <div class="figure-areas"></div> -->
      <img class="figure-areas" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/finger.png">
    </div>
    <div>
      <img class="logo-areas animate__animated  animate__zoomIn" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png">
    </div>

    <div class="gradents-text f-setence animate__animated  animate__zoomIn">
      <div class="join-txt" lang="en"> JOIN US</div>
      <div>同行者！由你定义这个时代</div>
      <div>Fellow pioneers! It's up to you to define this era.</div>
    </div>
    <div class="gradents-text s-setence animate__animated  animate__zoomIn">
      <div>1776年蒸汽时代，需要释放能量的人！</div>
      <div>1879年电力时代，需要照亮黑夜的人！
      </div>
      <div>1981年计算机时代，需要突破算力的人！
      </div>
      <div>1994年互联网时代，需要连接你我的人！
      </div>
      <div>2012年跨境时代，需要征服出海的人！
      </div>
      <div>{{ currentYear }}年，这是一个怎样的时代？</div>
      <div>在索迈，由你来定义！
      </div>
    </div>
  </div>
  <div class="mycontainer1 cus-cpn-parts">
    <div style="position: relative; padding-top: 14vh;">
      <div id="work-lamp" class="dep-imgs">
        <img class="work-icon" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/lamp.png">
        <div class="gradents-text working-title">
          Office 办公区
        </div>
      </div>
      <div style="opacity: 0;" id="work-coffee" class="dep-imgs">
        <img style=" " class="work-icon" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/headset.png">
        <div class="gradents-text working-title">
          Lounge Area 休息区
        </div>
      </div>

      <div style="opacity: 0;" id="work-plat" class="dep-imgs">
        <img style=" " class="work-icon" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/plat.png">
        <div class="gradents-text working-title">
          Dining Area 用餐区
        </div>
      </div>
    </div>


    <div class="gradents-text work-txt-areas">
      <div class="gradents-text work-txt-dtl" style="margin-top: 1vh">
        {{ icon_text }}
      </div>

    </div>
    <div class="">
      <img class="pad-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/ipad.png">
      <img class="lamp-follow-pic" :src="pad_img" alt="">
      <!-- <img id="work-plat"  class="pad-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/lampimg.png"> -->
      <!-- <img id="work-plat"  class="pad-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/coffeeimg.png"> -->
    </div>
  </div>


  <div class="mycontainer1 mobile-container">
    <div class="top-img-icon">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/lamp.png">
      <div class="gradents-text"> Office 办公区</div>
    </div>
    <div class="intro-cn-txts">
      <div class="gradents-text">作为索迈全球新总部，索迈大厦于2020年09月正式启用。除此以外，我们在美国、德国等多个海外国家
        均建立了分支机构并为当地员工打造了舒适、创新，体现索迈文化特色的办公环境
      </div>
    </div>
    <div class="work-areas-img">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/lampimg.png">
    </div>

  </div>

  <div class="mycontainer1 mobile-container">
    <div class="top-img-icon">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/headset.png">
      <div class="gradents-text"> Lounge 休息区</div>
    </div>
    <div class="intro-cn-txts">
      <div class="gradents-text">索迈一直以来坚持提供多和谐、多交流、多元文化融合发展的工作环境，工作区与休息区“互联互通”的用心设计， 意在将各个角落透明打通，实现人与人、人与工作、人与休闲的连接，体现索迈文化特色的办公环境。
      </div>
    </div>
    <div class="work-areas-img">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/headsetimg.png">
    </div>

  </div>

  <div class="mycontainer1 mobile-container">
    <div class="top-img-icon">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/plat.png">
      <div class="gradents-text"> Dining Area 用餐区</div>
    </div>
    <div class="intro-cn-txts">
      <div class="gradents-text">暖心的用餐区不仅是食用工作餐的场地，也是员工之间放松畅聊的最佳角落。
      </div>
    </div>
    <div class="work-areas-img">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/platimg.png">
    </div>

  </div>


  <div class="mycontainer1" id="business-school">
    <div class="sm-busi">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/sm-busi.png" alt="somle bussiness scholl">
    </div>
    <div class="lead-area">
      <img class="lead-area-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/leaders.jpg" alt="somle-leaders">
      <div class="lead-area-txt">
        <div class="lead-area-txt-dtl">
          2017年索迈正式启动“商学院”计划，旨在加强企业人才的重点培养。
        </div>
        <div class="lead-area-txt-dtl" lang="en">
          In 2017, Somle officially launched the "Business School" program, It aims to strengthen the key
          training of enterprisee talents.
        </div>
      </div>
    </div>
  </div>

  <div class="mycontainer1" id="business-school-mobile">
    <div class="sm-busi">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/sm-busi.png" alt="somle bussiness scholl">
    </div>
    <div class="lead-area">
      <img id="sm-busi-mob" class="lead-area-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/leaders.jpg" alt="somle-leaders">
      <div class="lead-area-txt" id="sm-bu-txt-mb">
        <div class="lead-area-txt-dtl">
          2017年索迈正式启动“商学院”计划，旨在加强企业人才的重点培养。
        </div>
        <div class="lead-area-txt-dtl" lang="en">
          In 2017, Somle officially launched the "Business School" program, It aims to strengthen the key
          training of enterprisee talents.
        </div>
      </div>
    </div>
  </div>


  <div class="mycontainer1" id="join-img-container">
    <div class="join-img-gallery">
      <div v-for="image in images_ls" :key="image">
        <img :src="`https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/${image}`">

      </div>
    </div>


  </div>

  <div class="mycontainer1 cus-roll" id="roll-left-cho">
    <div id="roll-dep" class="roll-dep">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="somle logo">
      <div class="join-intro-texts">
        <p>在索迈，您会成为极具多元化且充满活力的社区中的一份子。</p>
        <p>集团员工来自多个国家/地区，无论您在我们的组织中担任何种角色，</p>
        <p>我们始终欢迎您表达自我、团结携作、拓宽知识、丰富职业网络并分享您对工作的热情。</p>
      </div>
    </div>
    <div id="vertical-col" class="vertical-col">

    </div>
    <div id="ready-texts" class="ready-texts">
      <div class="ready-texts-title" lang="en">
        Are you ready?
      </div>
      <div class="ready-texts-contents">
        <p lang="en">At Somle, you'll be part of a diverse and vibrant community.</p>
        <p lang="en">Group employees come from multiple countries, regardless of your role in our organization,</p>
        <p lang="en">We always welcome you to express yourself, collaborate, broaden your knowledge, </p>
        <p lang="en">enrich your professional network and share your passion for your work. </p>
      </div>
    </div>
  </div>

  <div class="mycontainer1 cus-roll" id="roll-left-cho-mob">
    <div id="roll-dep-mob" class="roll-dep">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="somle logo">
      <div class="join-intro-texts">
        在索迈，您会成为极具多元化且充满活力的社区中的一份子。
        集团员工来自多个国家/地区，无论您在我们的组织中担任何种角色，
        我们始终欢迎您表达自我、团结携作、拓宽知识、丰富职业网络并分享您对工作的热情。
      </div>
    </div>
    <div id="vertical-col-mob" class="vertical-col">

    </div>
    <div id="ready-texts-mob" class="ready-texts">
      <div class="ready-texts-title" lang="en">
        Are you ready?
      </div>
      <div class="ready-texts-contents" lang="en">
        At Somle, you'll be part of a diverse and vibrant community. Group employees come from multiple countries,
        regardless of your role in our organization, We always welcome you to express yourself, collaborate, broaden
        your knowledge, enrich your professional network and share your passion for your work.
      </div>
    </div>
  </div>


  <div class="mycontainer1" id="pos_search_container">
    <div class="gri_bor pos_search_container">
      <div class="pos_search">
        <div class="pos_search_input">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/searchicon.png" alt="search-icon">
          <input v-model="inputValue" id="pos_input_box" name="pos_input_box"/>
        </div>
        <div class="pos_search_cance">
          <img @click="handleClearBo" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/cancericon.png" alt="search-clear-input">
        </div>
        <div class="pos_search_button">
          <div @click="handleSearchBo" class="pos_search_button_text">职位搜索</div>
        </div>
      </div>
    </div>

    <div class="pos_loca_areas">
      <div class="pos_cate">
        <div class="">类别:</div>
        <div class="gri_bor_sm_sc pos_drop_sel">
          <div class="pos_cate_sel">
            <select v-model="selectedCategory">
              <option value="">---</option>

              <option value="财务">财务</option>
              <option value="视觉">视觉</option>
              <option value="供应链">供应链</option>
              <option value="设计研发">设计研发</option>
              <option value="国内业务">国内业务</option>
              <option value="海外运营">海外运营</option>
              <!-- ... -->
            </select>
          </div>
        </div>
      </div>
      <div class="pos_cate">
        <div class="">地点:</div>
        <div class="gri_bor_sm_sc pos_drop_sel">
          <div class="pos_cate_sel">
            <select v-model="selectedLocation">
              <option value="">---</option>
              <option value="宁波">宁波</option>
              <option value="美国">美国</option>
              <!-- ... -->
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="jobs_info_areas">
      <!-- 使用 v-for 循环来展示搜索结果 -->
      <div v-for="job in searchResults.slice(0, 4)" :key="job.id" class="gri_bor_sm job_card_par">
        <div class="job_card">
          <div class="job_card_title">
            {{ job.title }}
          </div>
          <div class="job_card_loc">
            {{ job.locate }}
          </div>
          <div class="job_card_line"></div>
          <div class="job_desc" v-html="job.req.split('; ').join('<p></p><p>')"></div>
          <div class="job_card_botton">
            <div class="job_card_botton_areas" :style="{ background: randomBackground() }">
              <a :href="job.bs_link" target="_blank">现在投递</a>
            </div>
          </div>
        </div>
      </div>
      <!-- 如果没有搜索结果，显示“没有工作”的消息 -->
      <div v-if="!pls_search && searchResults.length === 0" class="no-jobs">没有满足要求的工作...</div>
      <div v-if="pls_search" class="pls-search">请点击搜索...</div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';

const pls_search = ref(true);
const inputValue = ref('');
const selectedLocation = ref('');
const selectedCategory = ref('');
const searchResults = ref([]);

const posi_list = [
  {
    'cate': '海外运营',
    'locate': '宁波',
    'title': 'Amazon预备组长',
    'req': '独立运营管理本组产品; 负责亚马逊平台运营提升; 亚马逊统筹运营规划; 关键词追踪与广告策划',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '海外运营',
    'locate': '宁波',
    'title': 'Amazon运营',
    'req': '负责亚马逊各站点运营; 分析市场开发优势产品; 精确分析改进销售方法; 研究政策保持账号稳定',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },

  {
    'cate': '海外运营',
    'locate': '宁波',
    'title': '独立站运营',
    'req': '品牌独立站维护; 调查竞品并制订对应策略; 调研当地客户与产品优化; 建立海外KOL关系',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '海外运营',
    'locate': '宁波',
    'title': 'shopify海外推广',
    'req': 'Adwords Facebook推广; Shopify网站运营与维护; 售前与买家沟通; 制定运营推广方案',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '国内业务',
    'locate': '宁波',
    'title': '国内电商事业部总监',
    'req': '熟悉各大电商/APP平台; 制订经营目标及战略指标 ; 电商平台运营工作的推进; 平台品牌影响力整体提升',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '国内业务',
    'locate': '宁波',
    'title': '国内电商客服',
    'req': '解决客户需求顾客问题; 登记反馈和跟进客户需求 ; 引导顾客下单提升客单价 ; 处理好简单售后工作 ',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '设计研发',
    'locate': '宁波',
    'title': '家具工业设计师',
    'req': '完成产品属性定义; 完成产品外形设计; 协同结构设计的实现; 掌握行业内信息及趋势 ',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '设计研发',
    'locate': '宁波',
    'title': '结构工程师',
    'req': '项目立项可行性调研; 系统方案设计; 输出产品三维二维图; 零部件的详细设计',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '设计研发',
    'locate': '宁波',
    'title': '产品经理',
    'req': '制定市场战略规划路线图;  定义具备竞争力的产品; 参与产品的运营和推广; 协助产品开发、上市',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '设计研发',
    'locate': '宁波',
    'title': 'CMF高级设计师',
    'req': '评估并促进新工艺落地;  制定产品线CMF规范; 完成前期CMF趋势分析; 制作设计说明',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '供应链类',
    'locate': '宁波',
    'title': '采购开发工程师',
    'req': '根据需求进行市场调研;  降低采购成本; 拓宽公司产品品类; 负责供应商管理',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '供应链类',
    'locate': '宁波',
    'title': '海外仓物流专员/主管',
    'req': '管理跟进海外仓; 管理海外仓相关单据; 更新海外仓各项信息文件; 核查海外仓库货物数据',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '视觉类',
    'locate': '宁波',
    'title': '3D动画/渲染师',
    'req': '产品渲染及后期处理;  优化效果图; 制作3d动画演示; 输出产品渲染线稿图',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '视觉类',
    'locate': '宁波',
    'title': '平面设计师',
    'req': '产品图片的后期处理; 活动推广图优化; 有良好的创意思维; 沟通能力和团队合作精神',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '财务',
    'locate': '宁波',
    'title': '财务副经理',
    'req': '负责境内外的财务核算; 资产盘点的跟踪; 高新技术企业评审和维护; 配合外部审计及相关工作',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  },
  {
    'cate': '财务',
    'locate': '宁波',
    'title': '出纳/财务助理',
    'req': '管理公司个银行账户; 负责银行结算业务; 确保资金准确性和安全性; 认真审核各种报销',
    'id': 2,
    'bs_link': 'https://www.zhipin.com/gongsi/job/70d81ec2f8d9fbd81H1_3dm_EA~~.html?ka=company-jobs'
  }





];

const handleClearBo = () => {
  inputValue.value = '';
};

const handleSearchBo = () => {
  pls_search.value = false;
  const keyword = inputValue.value.toLowerCase();
  const location = selectedLocation.value;
  const category = selectedCategory.value;


  searchResults.value = posi_list.filter(pos => {
    return (
        pos.locate.toLowerCase().includes(location.toLowerCase()) &&
        pos.cate.toLowerCase().includes(category.toLowerCase()) &&
        (
            pos.title.toLowerCase().includes(keyword) ||
            pos.req.toLowerCase().includes(keyword)
        )
    );
  });
};
</script>


<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";


export default {
  name: "JoinUs",

  data() {
    return {
      pad_img: 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/lampimg.png',

      images_ls: Array.from({length: 55}, (v, i) => `gallery${i + 1}.jpg`),
      icon_text:'作为索迈全球新总部，索迈大厦于2020年09月正式启用。除此以外，我们在美国、德国等多个海外国家均建立了分支机构并为当地员工打造了舒适、创新，体现索迈文化特色的办公环境。',
      currentYear: new Date().getFullYear()


    };
  },
  mounted() {
        document.title = '加入我们|SOMLE'

    if (window.innerWidth >= 1030) {
      this.executeAnLargeScreen();
    } else {
      this.executeAnSmallScreen();
    }


  },
  computed: {
  truncatedReq() {
    let truncatedText = this.job && this.job.req ? this.job.req.substring(0, 11) + '..' : '';
    return truncatedText.split('; ').join('<p></p><p>');
  }
},


  methods: {
    randomBackground() {
      const randomDeg = Math.floor(Math.random() * 361);
      return `linear-gradient(${randomDeg}deg, #2a57a2, #4a8f89, #63b572, #aba356, #d59846, #d6853e, #d87737, #db5526, #df2212, #de2411, #de2411)`;
    },
    handleClearBo() {
      document.getElementById('pos_input_box').value = '';
    },
    handleSearchBo() {

    },


    executeAnLargeScreen() {
      gsap.registerPlugin(ScrollTrigger);


//处理休息区不同区


      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".cus-cpn-parts",
          start: "top top",
          end: "bottom center",
          pin: true,
          scrub: true,
              toggleActions: "play none none none"

        }
      });

      // 第一个动画：让work-lamp元素向上移动并变为透明
      tl.to("#work-lamp", {
        y: "-100%",
        opacity: 0,
        duration: 1,
      });

      // 设置work-coffee元素的初始状态
      tl.set("#work-coffee", {
        y: "40%", // 位置设置为和work-lamp一样
        opacity: 0, // 完全透明
        display: 'initial' // 显示元素
      }, "-=1"); // 与上一个动画同时开始

      // 第二个动画：让work-coffee元素向下移动并变为不透明
      tl.to("#work-coffee", {
        y: "0%",
        opacity: 1,
        duration: 2,
        display: 'initial',
        onStart: () => {
          this.pad_img = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/coffeeimg.png'
          this.icon_text= '索迈一直以来坚持提供多和谐、多交流、多元文化融合发展的工作环境，工作区与休息区“互联互通”的用心设计，意在将各个角落透明打通，实现人与人、人与工作、人与休闲的连接，体现索迈文化特色的办公环境。\n'
        }
      })


      tl.to("#work-coffee", {
        y: "-100%",
        opacity: 0,
        duration: 1,
      });


      // 设置work-coffee元素的初始状态
      tl.set("#work-plat", {
        y: "40%", // 位置设置为和work-lamp一样
        opacity: 0, // 完全透明
        display: 'initial' // 显示元素
      }, "-=1"); // 与上一个动画同时开始
      tl.to("#work-plat", {
        y: "0%",
        opacity: 1,
        duration: 2,
        display: 'initial',
        onStart: () => {
          this.pad_img = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/platimg.png'
          this.icon_text= '暖心的用餐区不仅是食用工作餐的场地，也是员工之间放松畅聊的最佳角落.'
        }
      })




      let element2 = document.querySelector('.lead-area-img');
      let rect2 = element2.getBoundingClientRect();
      let img_elementWidth_cal = element2.offsetWidth;
      let txt_offset = rect2.right - img_elementWidth_cal / 2

      let lead_sec = gsap.timeline({
        scrollTrigger: {
          trigger: "#business-school",
          start: "top top",
          end: "bottom center",
          pin: true,
          scrub: true,
        }
      })
      // 获取浏览器视口的尺寸
      var svw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      // 获取元素的尺寸和位置
      // var element = document.querySelector('.sm-busi');
      // var rect = element.getBoundingClientRect();

      // 计算元素需要移动的距离
      var dx = -svw / 2 + 0.1 * svw;

      // 创建动画
      lead_sec.to(".sm-busi", {
        x: dx,
        y: 45,
        duration: 2, // 动画的持续时间
      }, 0);


      let img_element = document.querySelector('.lead-area-img');
      let img_elementWidth = img_element.offsetWidth;
      let img_offset = img_elementWidth * 0.5; // 计算50%的宽度

      lead_sec.to('.lead-area-img', {
        x: -img_offset,
        duration: 2 // 动画的持续时间
      }, 0);


      // let txt_element = document.querySelector('.lead-area-txt');
      // let txt_elementWidth = txt_element.offsetWidth;
      // let txt_offset = txt_elementWidth * 0.5; // 计算50%的宽度
      // // console.log(txt_offset)

      lead_sec.to('.lead-area-txt', {
        x: txt_offset,
        duration: 2 // 动画的持续时间
      }, 0)


      //图像由灰度变为彩色
      let gray_colors = gsap.timeline({
        scrollTrigger: {
          trigger: "#join-img-container",
          start: "top top",
          end: "bottom center",
          scrub: true,
          onEnter: () => gray_colors.play(),
          onLeaveBack: () => gray_colors.reverse(),
        }
      });
      // 首先设置初始灰度
      gsap.set(".join-img-gallery div img", {
        filter: "grayscale(100%)", // 100%灰度，即完全灰色
      });
      gray_colors.to(".join-img-gallery div img", {
        duration: 1, // 持续时间为1秒
        filter: "grayscale(0%)", // 目标是0%灰度，即全彩色
        ease: "easeInOut" // 缓动效果

      })


      let scroll_roll = gsap.timeline({
        scrollTrigger: {
          trigger: "#roll-left-cho",
          start: "top top",
          end: "bottom center",
          // scrub: true,
          pin: true,

          onEnter: () => scroll_roll.play(),
          onLeaveBack: () => scroll_roll.reverse(),
        }
      });

      scroll_roll.to(".roll-dep", {
        opacity: 0,
        duration: 3,
      }).pause(); // 将动画初始设置为暂停状态

      gsap.set("#ready-texts", { // Set initial state for ready-texts
        clipPath: "inset(0 0 0 100%)"
      });

      scroll_roll.to("#ready-texts", {
        clipPath: "inset(0 0% 0 0%)", // Show the element from right to left
        duration: 1.2,
      }, 3.2);

      scroll_roll.to("#vertical-col", {
        left: 280,
        duration: 1.5,
      }, 3);


      let scaletran = gsap.timeline({})
      scaletran.fromTo('.gradents-text, .logo-areas', {
        opacity: 0.1,
        scale: 1.0
      }, {
        opacity: 1,
        scale: 1.05,
        duration: 2
      })

      // 搜索页吸附顶部
      gsap.timeline({
        scrollTrigger: {
          trigger: "#pos_search_container", // 使用#sticky-element作为触发器
          start: "top top", // 当触发元素的顶部与视口的顶部对齐时开始
          end: "bottom bottom", // 当触发元素的底部与视口的底部对齐时结束
          pin: true, // 吸附效果
          scrub: true, // 平滑的滚动效果
        }
      });


    },
    executeAnSmallScreen() {


      //商学院动画效果。
      gsap.registerPlugin(ScrollTrigger);
      let bs_tw = gsap.timeline({
        scrollTrigger: {
          trigger: "#business-school-mobile",
          start: "top top",
          end: "bottom center",
          // scrub: true,
          pin: true

        }
      });

      // 选择要动画的元素
      const targetElement = document.querySelector('#sm-busi-mob');

// 创建动画
      bs_tw.to(targetElement, {
        y: '-50%', // 上移动元素自身的50%高度
        duration: 1.5, // 持续时间为1秒
        ease: 'easeInOut', // 缓动效果
      });
      bs_tw.to("#sm-bu-txt-mb", {
        y: '+50%', // 上移动元素自身的50%高度
        duration: 1.5, // 持续时间为1秒
        ease: 'easeInOut', // 缓动效果
      }, 0);


      //处理手机端商学院动画
      let lead_sec = gsap.timeline({
        scrollTrigger: {
          trigger: "#roll-left-cho-mob",
          start: "top top",
          end: "bottom center",
          pin: true,
          scrub: true,
        }
      })

      const moveDistance = -(window.innerHeight * 0.65);
      lead_sec.to('#roll-dep-mob', {
        opacity: 0,
        duration: 1.2

      })

      // 创建动画
      lead_sec.to("#vertical-col-mob", {
        y: moveDistance,
        duration: 2, // 动画的持续时间
      }, 0);

      gsap.set("#ready-texts-mob", { // Set initial state for ready-texts
        clipPath: "inset(100% 0  0  0 )"
      });

      lead_sec.to("#ready-texts-mob", {
        clipPath: "inset(0% 0 0 0)", // Show the element from right to left
        duration: 1.5,
      }, 0.3);


      //图像由灰度变为彩色
      let gray_colors = gsap.timeline({
        scrollTrigger: {
          trigger: "#join-img-container",
          start: "top top",
          end: "bottom center",
          scrub: true,
          onEnter: () => gray_colors.play(),
          onLeaveBack: () => gray_colors.reverse(),
        }
      });
      // 首先设置初始灰度
      gsap.set(".join-img-gallery div img", {
        filter: "grayscale(100%)", // 100%灰度，即完全灰色
      });
      gray_colors.to(".join-img-gallery div img", {
        duration: 2, // 持续时间为1秒
        filter: "grayscale(0%)", // 目标是0%灰度，即全彩色
        ease: "easeInOut" // 缓动效果

      })


    }


  }
}

</script>

<style scoped>

.figure-pare{
  margin-top: 10vh;
}



#roll-left-cho-mob {
  display: none;
}


#business-school-mobile {
  display: none;
}

.mobile-container {
  display: none;
}

.mycontainer1 {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #101010;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.gri_bor {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  padding: 1px;
  border-radius: 4px;
}


.gri_bor_sm {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  padding: 2px;
  border-radius: 30px;
}

.gri_bor_sm_sc {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  padding: 1px;
}

.pos_search_container {
  margin-top: 5%;
  margin-left: 15%;
  position: relative;
  width: 70%;
  /* max-width: 1300px; */
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.pos_search {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 24px;

}

.pos_search_icon,
.pos_search_cance {
  height: 100%;

}


.pos_search_cance img {
  height: 70%;
  padding: 10%;
  object-fit: contain;
}


.pos_search_input img {
  height: 70%;
  padding-left: 1%;
  padding-right: 1%;
  object-fit: contain;
}


.pos_search_button_text {
  color: white;
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  border-radius: 0.6rem 0.2rem 0.2rem 0.6rem;
  font-size: 1rem;
  text-align: center;
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.pos_search_input {
  height: 100%;
}

#pos_input_box {
  height: 100%;
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
}

#pos_input_box:focus {
  outline: none;
  border: none;
  box-shadow: none;
}


.pos_search_cance {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 5%;

}

.pos_search_input {
  flex-basis: 85%;
  display: flex;
  align-items: center;
}

.pos_search_button {
  flex-basis: 10%;
  height: 100%;
}

.pos_loca_areas {
  width: 70%;
  margin-left: 15%;
  color: white;
  display: flex;
  margin-top: 2vh;
}

.pos_cate {
  flex-basis: 18%;
  padding-right: 1rem;

}

.pos_drop_sel {
  margin-top: 1vh;;
}

.pos_cate_sel {

  width: 100%;
  background-color: black;
}


.pos_cate_sel select {

  background-color: black;
  color: white;
  font-size: 1vw;
  padding: 0.6rem;
  text-align: center;
  width: 100%;
  border: none;

}

select.custom-select {
  background-color: #f2f2f2;
  border: 1px solid #888;
  padding: 10px;
}

#pos_cate_sel:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.jobs_info_areas {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-left: 15%;
  margin-top: 3vh;
  flex-wrap: wrap;
  align-items: flex-start

}

.job_card {
  background-color: black;
  padding: 2rem;
  border-radius: 30px;
}

.job_card_par {
  flex-basis: 16vw;
  margin-bottom: 1rem;

}

.job_card_botton_areas {
  /*background: linear-gradient(60deg, #2a57a2, #4a8f89, #63b572, #aba356, #d59846, #d6853e, #d87737, #db5526, #df2212, #de2411, #de2411);*/
  border-radius: 0.6rem 0.4rem 0.4rem 0.6rem;
  width: fit-content;
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
}

.job_card_botton_areas a {
  color: white;
}

.job_card_botton_areas a:hover {
  text-decoration: none;
}

.job_desc {
  padding-top: 2vh
}

.job_card_botton {
  text-align: center;
  display: flex;
  justify-content: center;
}

.job_card_loc {
  text-align: right;
}

.job_card_line {
  height: 1px;
  margin-top: 1rem;
  width: 100%;

  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
}

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #101010;

}

.cus-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}

.work-txt-dtl{
  width: 50%;
  text-align: center;
}


.figure-areas {
  width: 3.6vw;

}

.logo-areas {
  margin-top: 7vh;
  width: 9vw;
  margin-bottom: 7vh;
}

.gradents-text {
  background: linear-gradient(to left, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join-txt {
  font-size: 1.6vw;
  margin-bottom: 0.5rem;
}


.f-setence {
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-size: 1vw;
  font-weight: bold;
}

.s-setence {
  /* margin-bottom:3rem; */
  font-size: 1.0vw;
  font-weight: bold;
}

.pad-img {
  width: 35vw;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.lamp-follow-pic {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 34vw;
}

.work-icon {
  height: 5vw;
  display: initial;
}

.work-txt-areas {

  margin-top: 11vh;
  margin-bottom: 3rem;

}

.working-title {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.5vw;
  font-weight: bold;
  white-space: nowrap;
}

.sm-busi {

  display: flex;
  justify-content: flex-end;
  padding: 4vw;
}

.sm-busi img {
  width: 18vw;
  padding-left: 3rem;
}

.lead-area {
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  margin-top: 7rem;

}

.lead-area-img {
  z-index: 4;
  width: 35%;
  height: 100%;
  position: relative;
}

.lead-area-txt {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 35%;
  height: 99.4%;
  background-color: black;
  left: 0;
  z-index: 1;
  transform: translate(93%, 0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.roll-dep {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  color: white;

}

.roll-dep img {
  width: 10vw;

}

.cus-roll {
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-intro-texts {
  font-size: 1vw;
}


.vertical-col {
  width: 0.6vw;
  position: absolute;
  height: 84%;
  text-align: center;
  background: linear-gradient(to bottom, #e21307, #fdb850, #55d08f, #2b5eaf);
  border-radius: 0.8rem;
  right: 16%;

}


.ready-texts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  display: flex;

  /* display: none; */
  flex-direction: column;
  clip-path: inset(0 100% 0 0);


}


.ready-texts-title {
  font-size: 4vw;


}

@keyframes reveal {
  from {
    clip-path: circle(0% at 50% 50%);
  }

  to {
    clip-path: circle(100% at 50% 50%);
  }
}

.figure-areas {
  animation: reveal 2s forwards;
}

.dep-imgs {
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

}



.lead-area-txt-dtl {
  color: white;
  width: 65%;
  font-size: 1vw;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.cus-cpn-parts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.join-img-gallery {
  margin-top: 8vh;
  text-align: center;
  padding: 3vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.join-img-gallery div img {
  width: 8vw;
  padding-bottom: 0.6vw;
  filter: grayscale(100%);
}

.ready-texts-contents{
  font-size: 1vw;
}

@media (min-width: 200px) and (max-width: 1030px) {
  .sm-busi img{
    width: 50vw;
    margin-top: 10vh;
  }



  .lead-area-txt-dtl {
    font-size: 3vw;
  }

  .pos_cate_sel select {
    font-size: 2.8vw;
  }
  .jobs_info_areas {
    /*flex-direction: column;*/
    /*align-items: initial;*/
  }


  .join-img-gallery {
    margin-top: 7vh;
    text-align: center;
    padding: 4vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .join-img-gallery div img {
    padding: 2px;
    width: 15.8vw;
    filter: grayscale(100%);

  }

  .ready-texts-contents {
    font-size: 3.5vw;
    margin-top: 5vh;
  }

  .ready-texts-title {
    font-size: 10vw;
    font-weight: bold;
  }

  .ready-texts {
    width: 80%;
  }

  .vertical-col {
    top: 86%;
    margin-left: 10%;
    width: 80%;
    right: 0;
    position: relative;
    height: 0.5rem;
    text-align: center;
    background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
    border-radius: 0.8rem;

  }

  .join-intro-texts {
    margin-top: 4vh;
    line-height: 1.8;
    font-size: 2.8vw;
  }

  #roll-dep-mob {
    /*margin-left: 10%;*/
    width: 75%;
  }

  .roll-dep img {
    width: 28vw;
  }

  #roll-left-cho {
    display: none;
  }

  #roll-left-cho-mob {
    display: inherit;
  }

  .sm-busi {
    margin-top: 5vh;
  }

  #business-school-mobile {
    display: inherit;
  }

  #business-school {
    display: none;
  }

  .lead-area-img {
    width: 80%;
  }

  .lead-area {
    margin-top: 50%
  }

  .lead-area-txt {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: black;
  }

  .pos_search_button_text {
    border-radius: 0.3rem 0.4rem 0.4rem 0.3rem;
  }


  option {
    border: none;
    text-align: left;
    background-color: transparent;
    width: auto;
  }


  #pos_input_box {
    font-size: 1rem;
  }

  .job_card {
    padding: 0.8rem;
  }

  .job_card_title, .job_card_loc {
    font-size: 0.8rem;
  }

  .job_desc {
    font-size: 2.8vw;
  }

  .job_card_botton_areas {
    font-size: 2.8vw;
  }

  .pos_search_button_text {
    font-size: 0.8rem;
  }

  .job_card_par {
    flex-basis: 48%;
  }


  .jobs_info_areas, .pos_search_container, .pos_loca_areas {
    width: 90%;
    margin-left: 5%;

  }


  .pos_cate {
    flex-basis: 35%;
  }


  .pos_search_button {
    flex-basis: 29%;
    height: 100%;
  }

  .pos_search_container {
    height: 37.5px;
  }

  .figure-areas {
    width: 9vw;
    margin-top: 1rem
  }

  .logo-areas {
    /*margin-top: 0rem;*/
    /*margin-bottom: 2rem;*/
    /*padding-bottom: 10rem;*/
    width: 28vw;
  }

  .join-txt {
    font-size: 1.0rem;
    font-weight: bold;
    font-family: Arial;
  }

  .f-setence {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .s-setence {
    font-size: 0.8rem;
    width: 67%;
    text-align: center;

  }

  .mobile-container {
    display: flex;

  }

  .cus-cpn-parts {
    display: none;
  }

  .top-img-icon {
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .top-img-icon img {
    width: 4rem;
  }

  .top-img-icon div {
    font-size: 1rem;
    padding: 1rem
  }

  .intro-cn-txts {
    /*width: 70%;*/
    text-align: center;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-top: 4vh;
    padding-bottom: 4vh;
    font-size: 2.8vw;

  }

  .intro-cn-txts div {
    line-height: 1.8;
  }

  .work-areas-img {
    margin-top: 5vh;
    text-align: center;
  }

  .work-areas-img img {
    width: 75%;
  }

  .sm-busi {
    justify-content: center;
  }
  .job_desc p{

  }

}

#business-school, #business-school-mobile {
  background-color: #111111;
}

@media (min-width: 800px) and (max-width: 1030px) {
  .lead-area {
    margin-top: 25%;
  }

  .sm-busi {
    justify-content: center;
  }


}


@media only screen and (min-width: 601px) and (max-width: 1030px) {
  /* 平板样式 */
  .join-img-gallery div img {
    width: 10vw;

  }



  .join-img-gallery {

    padding: 21vw;

  }

  .sm-busi {
    justify-content: center;
  }

}


</style>
<template >

  <div class="mycontainer1 footer-main" v-if="shouldShowFooter">
    <div class="logo-midle">
      <img class="logo-midle-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="somle logo">

    </div>
    <div class="sologan-texts">
      <div class="sologan-texts-en" lang="en">
        To benefit the world, using our creativeness
      </div>
      <div class="sologan-texts-cn">
        用我们的创意惠及全球
      </div>
    </div>
    <div class="footer-copyright">
      ©2021-2023 宁波索迈视听科技有限公司 版权所有 ICP证：<a target="_blank" href="https://beian.miit.gov.cn/">浙ICP备14042697号</a>
    </div>
    <div class="footer-copyright-mobile">
      <div>©2021-2023 宁波索迈视听科技有限公司</div>
      <div>版权所有ICP证: <a target="_blank" class="gov-conv" href="https://beian.miit.gov.cn/">浙ICP备14042697号</a></div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'AppFooter',
    computed: {
    shouldShowFooter() {
      // 在组件中通过 this.$route.meta 来获取路由的 meta 信息
      return this.$route.meta.showFooter !== false;
    }
  }
};
</script>

<style scoped>
.footer-copyright-mobile {
  display: none;
}

.gov-conv{
  color: white;
}

.footer-copyright, .footer-copyright-mobile{
  color: white !important;
}

.footer-copyright a, .footer-copyright-mobile a{
  color: white !important;
}


.mycontainer1 {
  width: 100vw;
  height: 100vh;
  background: url("https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/footerbg.jpg");
  background-size: cover;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.footer-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

}


.logo-midle{
  margin-top: 2vh;
}

.logo-midle-img {
  width: 10vw;

}

.sologan-texts {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
  margin-top:5vh;
  padding: 4vh;
  line-height: 1.8;
  font-size: 1.0vw;
}

.footer-copyright {
  color: white;
  padding: 6vh;
  font-size: 1vw;
}

.sologan-texts-en {
  text-align: center;
  font-weight: bold;
}


@media (min-width: 350px) and (max-width: 1023px) {
  .footer-copyright-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .logo-midle{
    margin-top: 14vh;
  }
  .footer-copyright-mobile div {
    font-size: 2.5vw;
    color: white;
  }

  .footer-copyright {
    display: none;
  }

  .footer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo-midle-img {
    width: 35vw;
    max-width: 200px;
  }

  .footer-copyright {
    text-align: center;
  }

  .sologan-texts {
    margin-bottom: 15vh;
    margin-top: 5vh;
    font-size: 3vw;
  }


}


</style>

<template>


  <nav role="navigation" class="navigation">
    <div id="menuToggle">
      <input type="checkbox" v-model="menuOpen"/>
      <span></span>
      <span></span>
      <span></span>
      <ul id="menu">
        <li><a href="/" target="_blank" @click="toggleMenu" lang="en">HOME</a></li>
        <li><a href="/news" target="_blank" @click="toggleMenu" lang="en">NEWS</a></li>

        <li><a href="/brandsinfo" target="_blank" @click="toggleMenu" lang="en">BRAND</a></li>
        <li><a href="/introduction" target="_blank" @click="toggleMenu" lang="en">ABOUT</a></li>
        <li><a href="/joinus" target="_blank" @click="toggleMenu" lang="en">TALENT</a></li>
        <li><a href="/contactus" target="_blank" @click="toggleMenu" lang="en">CONTACT</a></li>


      </ul>
    </div>
  </nav>

</template>


<script>


export default {
  name: 'AppHeader',
  data() {
    return {
      menuOpen: false, // 用于控制菜单的打开和关闭

    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = false; // 切换菜单的打开状态
    },
    handleOutsideClick(event) {
      const menu = this.$el.querySelector('#menu');
      const toggle = this.$el.querySelector('#menuToggle');

      if (!menu.contains(event.target) && !toggle.contains(event.target)) {
        this.menuOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);

  },
  beforeUnmount() {
    // 组件销毁前移除事件监听器
    document.removeEventListener('click', this.handleOutsideClick);
  },

  computed: {
    headerCnTxt() {
      return this.$route.meta.isHomePage ? 'home_en_txt' : 'other_en_txt';
    },
    headerNavMain() {
      return this.$route.meta.isHomePage ? 'cus-big-nav' : 'cus-big-nav-other';
    },
  },
};
</script>

<style scoped>
.navigation {
  position: fixed;
  z-index: 19999;
}

#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: darkred;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 0.9;
  transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  max-width: 430px;
  width: 13vw;
  margin: -100px 0 0 -50px;
  padding: 1vw 0.5vw 1vw 3vw;
  padding-top: 125px;

  background: rgba(237, 237, 237, 0.3);
  backdrop-filter: blur(6px);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 0.6vw 0;
  font-size: 1.2vw;
}

#menuToggle input:checked ~ ul {
  transform: none;
  border-radius: 0 0 8px 0;
}

@media (min-width: 350px) and (max-width: 700px) {
  #menu {
    width: 100vw;
    max-width: 100vw;

  }

  #menu li {
    font-size: 4.5vw;
    padding:1vw 0vw 2vw 7vw;
  }


  #menuToggle span {
    width: 20px;
    height: 1px;
    transform-origin: 0px 0px;
  }


}


</style>

import {createRouter, createWebHistory} from 'vue-router'


// 导入你的组件
import AppHome from './views/Home.vue'
import AppNews from './views/News.vue'
import AppIntroduction from './views/Introduction.vue'
import ContactUs from "@/views/ContactUs";
import BrandInfo from "@/views/Brand";
import NewsDetail from "@/views/NewsDetail";
import TalentDev from "@/views/TalentDev";
import JoinUs from "@/views/JoinUs";

const routes = [
    {
        path: '/',
        component: AppHome,
        meta: {showFooter: false}

    },
    {
        path: '/news',
        component: AppNews,
    },
    {
        path: '/introduction',
        component: AppIntroduction,
    },
    {
        path: '/contactus',
        component: ContactUs,
    },
    {
        path: '/brandsinfo',
        component: BrandInfo,
    },
    {
        path: '/news/:id',
        name: 'NewsDetail',
        component: NewsDetail
    },
    {
        path: '/talentdev',
        name: 'talentdev',
        component: TalentDev
    },
    {
        path: '/joinus',
        name: 'JoinUs',
        component: JoinUs
    },

]


const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior() {

        return {x: 0, y: 0};
    }

})

export default router


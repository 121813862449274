<template>
  <div id="app">


    <div class="mycontainer1" id="brands-home-con">
      <div class="gradiant-color-texts">
        <div class="bran-gradiant-title" id="brand" lang="en">Its Brand</div>
        <div class="line-container" id="line-ares">
          <div class="line" id="line"></div>
        </div>

        <div class="gradiant-color sm-en-intro1" lang="en">
          <p lang="en">Somle owns 3 well-known brands,Distributed in three different fields of audio-visual
            technology,ergonomics, and home furnishing. Each of our brands uses innovation to bring unique
            value to
            each user.
          </p>
          <p>公司旗下FITUEYES、WAMPAT、FENGE三个标志性品牌，覆盖视听科技、人体工学、生活家居三大领域，以创新为用户带来独特的价值</p>
        </div>

      </div>
    </div>


    <div class="mycontainer1" id="brands-ball-con">

      <div class="move-color-areas-mobile">
        <div lang="en">We</div>
        <div lang="en">Create</div>
        <div lang="en">Meaningful</div>
        <div lang="en">Brand</div>
      </div>
      <div class="move-color-areas">
        <div class="move-color-texts-two">
          <div class="gradient-pare">
            <div class="text-mv-gr" id="we-gr" lang="en">We</div>
            <div class="text-mv-gradient" id="we-gr-gradient" lang="en">We</div>
          </div>
          <div class="gradient-pare">
            <div class="text-mv-gr" id="create-gr" lang="en">
              Create
            </div>
            <div class="text-mv-gradient" id="create-gr-gradient" lang="en">
              Create
            </div>
          </div>
        </div>
        <div class="move-color-texts-two">
          <div class="gradient-pare">
            <div class="text-mv-gr" id="meaningful-gr" lang="en">Meaningful</div>
            <div class="text-mv-gradient" id="meaningful-gr-gradient" lang="en">Meaningful</div>
          </div>
          <div class="gradient-pare">
            <div class="text-mv-gr" id="brand-gr" lang="en">
              Brand
            </div>
            <div class="text-mv-gradient" id="brand-gr-gradient" lang="en">
              Brand
            </div>
          </div>


        </div>

      </div>
      <div class="img-home-front">
        <div>

          <img class="hand_pic" style="position: absolute;"
               src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/mob-hand.png">
          <!--					<img id="fitpr" src="@/assets/imgs/fitpr.png">-->
          <img class="hand_pic" id="fitpr" :src="mobImg">


        </div>
      </div>

      <div class="intro-brand-main">
        <div class="brand-logo-l" id="fit-into">
          <img class="intro-pg-logo" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/fitueyes-1.png">
          <div class="text-intro-dtl-title" lang="en">
            For Visual Enjoyment
          </div>
          <div class="text-intro-dtl">
            Hello！我是FITUEYES特悦视，一个来自未来的全球视听家具品牌。我们为追求创新家居视听空间的人，提供满足新生活方式的视听家具。特悦视致力于将功能与美感并存的家居美学带给全球消费者，提供舒适愉悦的视听环境，革新家庭视听娱乐。
          </div>

        </div>

        <div class="brand-logo-l" id="fenge-into">
          <img class="intro-pg-logo" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/fenge-1.png">
          <div class="text-intro-dtl-title" lang="en">
            Smart Office Healthy Life Wisdom Choice
          </div>
          <div class="text-intro-dtl">
            致力于人体工学领域，为用户营造轻松灵动，健康办公的家居空间和商务空间。产品以设计创新为灵魂，以优质的品质惠及客户Fenge 从关注消费者的身体和心理健康出发，坚持用设计的力量改善人们生活。
          </div>
        </div>

        <div class="brand-logo-l" id="wam-into">
          <img class="intro-pg-logo" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/wampat-1.png">
          <div class="text-intro-dtl-title" lang="en">
            Warm Product Warm Life
          </div>
          <div class="text-intro-dtl">
            致力于提供非凡品质与高性价比的家具和家居用品，让消费者尽情品味美好的居家时光。产品以经典设计为本，风格完美融合美式传统经典与现代时尚，用温暖的设计细节、严格的生产工艺，满足消费者对品质的要求。改变人们家居购物的方式，以提升用户购物体验为主旨，打造属于每一个人的温馨之家。
          </div>

        </div>


      </div>

    </div>

    <!--pc pro card-->
    <div class="mycontainer1" id="brands-pro-main">
      <div class="grid-container-procd">

        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-a.jpg" alt="">
          <div class="overlay-procd">
            <p>FITUEYES ｜ 埃菲尔系列</p>
            <p>艺术落地电视支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-b.jpg" alt="">
          <div class="overlay-procd">
            <p>FITUEYES ｜ 大师系列</p>
            <p>艺术落地电视支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-c.jpg" alt="">
          <div class="overlay-procd">
            <p>FITUEYES ｜ 毕加索系列
            </p>
            <p>艺术落地电视支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-d.jpg" alt="">
          <div class="overlay-procd">
            <p>FITUEYES ｜ 收藏家系列
            </p>
            <p>艺术落地电视支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-e.jpg" alt="">
          <div class="overlay-procd">
            <p>FITUEYES ｜ 禅系列
            </p>
            <p>艺术落地电视支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-f.jpg" alt="">
          <div class="overlay-procd">
            <p>WAMPAT ｜ 柜子系列
            </p>
            <p>现代家居储物柜</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-g.jpg" alt="">
          <div class="overlay-procd">
            <p>WAMPAT ｜ 茶几系列</p>
            <p>现代家居储物柜</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-h.jpg" alt="">
          <div class="overlay-procd">
            <p>WAMPAT ｜ 柜子系列</p>
            <p>现代家居梳妆台</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-i.jpg" alt="">
          <div class="overlay-procd">
            <p>WAMPAT ｜ 边桌系列
            </p>
            <p>现代家居边桌</p>
          </div>
        </div>
        <div class="grid-item-procd">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-j.jpg" alt="">
          <div class="overlay-procd">
            <p>FENGE ｜ 支架系列
            </p>
            <p>创意显示器支架</p>
          </div>
        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-k.jpg" alt="">
          <div class="overlay-procd">
            <p>FENGE ｜ 电动桌系列
            </p>
            <p>创意电动可调节办公桌
            </p>
          </div>
        </div>

        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pc-p-l.jpg" alt="">
          <div class="overlay-procd">
            <p>FENGE ｜ 站立式桌系列
            </p>
            <p>创意站立式可调节办公桌
            </p>
          </div>
        </div>


        <!-- 重复以上结构，直到最后一个 grid-item -->
        <div class="grid-item-procd grid-item-large-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pro-logo.png" alt="">

        </div>
      </div>


    </div>


    <!--mb-pro-card-->
    <div class="mycontainer1" id="brands-pro-main-mb">
      <div class="grid-container-procd">

        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-a.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-b.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-c.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-d.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-e.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-f.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-g.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-h.jpg" alt="">

        </div>
        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-i.jpg" alt="">

        </div>

        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-j.jpg" alt="">

        </div>


        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-k.jpg" alt="">

        </div>
        <div class="grid-item-procd">

        </div>

        <div class="grid-item-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/mb-p-l.jpg" alt="">

        </div>


        <!-- 重复以上结构，直到最后一个 grid-item -->

        <div class="grid-item-procd grid-item-large-procd">
          <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/pro-logo.png" alt="">

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {
  gsap
} from "gsap";

import {
  ScrollTrigger
} from "gsap/ScrollTrigger.js";

import {
  MotionPathPlugin
} from "gsap/MotionPathPlugin.js";


export default {

  name: "BrandInfo",
  data() {
    return {
      imageLink: '',
      companyName: '',
      mobImg: 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/fitpr.png'
    };
  },
  mounted() {
    document.title = '品牌|SOMLE'
    if (window.innerWidth >= 1030) {
      this.executeAnLargeScreen();
    } else {
      this.executeAnSmallScreen();
    }


  },
  methods: {
    executeAnSmallScreen() {
      let vm = this;

      gsap.registerPlugin(ScrollTrigger)

      let t5 = gsap.timeline({
        scrollTrigger: {
          trigger: "#brands-ball-con",
          start: "top top",
          end: "bottom center",
          pin: true,
          scrub: 1,
          reverse: false,
        }
      })


      t5.fromTo("#fit-into", {
        duration: 0.5,
        display: "none"
      }, {
        duration: 1,
        display: "block"
      })

      t5.to("#fit-into", {
        // 从不透明变为透明
        opacity: 0,
        duration: 1,
        onComplete: function () {
          // 在动画完成时设置display: none
          document.getElementById("fit-into").style.display = 'none';
          // document.getElementById("fitpr").src = "@/assets/imgs/fengepr.png"
          vm.mobImg = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/fengepr.png'

        }
      }).add(
          gsap.fromTo("#fenge-into", {
            duration: 1,
            display: "none"
          }, {
            duration: 2,
            display: "block"
          })
      )

      t5.to("#fenge-into", {
        // 从不透明变为透明
        opacity: 0,
        duration: 1,
        onComplete: function () {
          // 在动画完成时设置display: none

          var fenge_element = document.getElementById("fenge-into");

          if (fenge_element) {
            fenge_element.style.display = 'none';
          }

          vm.mobImg = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/wampr.png'


        }
      }).add(
          gsap.fromTo("#wam-into", {
            duration: 1,
            display: "none"
          }, {
            duration: 2,
            display: "block"
          })
      )

    },
    executeAnLargeScreen() {
      // 在此处执行大屏动画
      document.querySelectorAll('.grid-item-procd').forEach(item => {
        item.addEventListener('click', () => {
          item.querySelector('.overlay-procd').style.visibility = 'visible';
        });
      });

      let vm = this;
      gsap.registerPlugin(ScrollTrigger)
      let brand_mv = gsap.timeline(

      )

      brand_mv.to(
          "#brand", {
            fontSize: "8vw",
            duration: 2,
            onComplete: function () {
              // var brandWidth = document.getElementById('brand').offsetWidth;
              document.getElementById('line-ares').style.width = document.getElementById('brand')
                  .offsetWidth + "px";
              document.getElementById('line').style.animation = "lineAnimation 2s forwards";
            }
          }
      )
      brand_mv.to(
          '#line', {}
      )


      gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

      let tl = gsap.timeline();

      // 动画1: 对 #fit-into 的动画
      tl.to("#fit-into", {
        opacity: 0,
        duration: 1,
        onComplete: function () {
          document.getElementById("fit-into").style.display = 'none';
          vm.mobImg = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/fengepr.png'
        }
      });

      // 动画2: 对 #fenge-into 的显示
      tl.fromTo("#fenge-into", {
        duration: 1,
        display: "none"
      }, {
        duration: 2,
        display: "block",
        onComplete: function () {
          // document.getElementById("fit-into").style.display = 'none';
          // vm.mobImg = require('@/assets/imgs/fengepr.png');
        }
      });

      // 动画3: 对 #fenge-into 的透明度变化
      tl.to("#fenge-into", {
        opacity: 0,
        duration: 1,
        onComplete: function () {
          document.getElementById("fenge-into").style.display = 'none';
          vm.mobImg = 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/wampr.png'
        }
      });

      // 动画4: 对 #wam-into 的显示
      tl.fromTo("#wam-into", {
        duration: 1,
        display: "none"
      }, {
        duration: 2,
        display: "block"
      });

      ScrollTrigger.create({
        trigger: "#brands-ball-con",
        start: "top top",
        end: "bottom center",
        // markers: true,
        pin: true,
        scrub: true,
        animation: tl
      });


    }
  }

}
</script>

<style scoped>
#brands-pro-main-mb{
  display: none;
}
.grid-container-procd {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  /* 元素间的间隔 */
  justify-items: center;
  /*padding: 10vh 16vw;*/
  margin-left: 10vw;
  height: 85vh;
  width: 80vw;
}

.grid-item-procd {
  position: relative;
  padding: 1vw;
  /* 用于定位遮罩 */

}

.overlay-procd p {
  margin-top: 0.6rem !important;
  margin-bottom: 0px !important;
  padding-left: 1vw;
  padding-top: 0.6vw;

}

.overlay-procd p:first-of-type {
  font-size: 0.9vw;
}

.overlay-procd p:nth-last-child(1) {
  font-size: 0.8vw;
}

.grid-item-large-procd {
  grid-column: span 2;
  /* 合并最后两列 */
}


.grid-item-procd img {
  height: 100%;
  width: 100%;
  display: block;
  /*background-color: black;*/
}


.overlay-procd {
  position: absolute;
  bottom: 1vw;;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  /* 半透明遮罩 */
  color: white;
  text-align: center;
  visibility: hidden;
  /* 初始不可见 */
  height: 33%;
  /* 高度为图片的 1/3 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1vw 0 1vw;
}

.grid-item-procd:hover .overlay-procd,
.grid-item-procd:active .overlay-procd {
  visibility: visible;
}


.hand_pic {
  width: 55vw;
}


.mycontainer1 {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: #101010;
}

body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.bran-gradiant-title {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 8vw;
  font-weight: bolder;
  display: inline-block;
  margin-top: 10%;

}

.move-color-areas-mobile {
  display: none;
}

.line {
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);

}

@keyframes lineAnimation {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.gradiant-color {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1vw;
  width: fit-content;
  margin-top: 1rem;
}

.gradiant-color-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-color-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-color-texts {
  color: white;
  background: linear-gradient(to left, rgb(16, 85, 189), rgb(106 157 193), rgb(38, 149, 201), rgb(141 135 45), rgb(221, 210, 57), rgb(230, 61, 32), rgb(230, 61, 32), rgb(141 26 4));
  -webkit-background-clip: text;
  color: transparent;
  font-size: 6.5rem;
  width: fit-content;
  margin-top: 1rem;
  padding: 0.5rem;
  font-weight: bolder;
}

.move-color-texts-two {
  display: flex;
}


.gradient-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: linear-gradient(135deg, rgb(16, 85, 189), rgb(106 157 193), rgb(38, 149, 201), rgb(228, 218, 75), rgb(221, 210, 57), rgb(230, 61, 32), rgb(230, 61, 32), rgb(141 26 4));
}

.sm-en-intro1 {
  width: 57%;
}

@media (min-width: 350px) and (max-width: 1030px) {
  #brands-pro-main-mb{
  display: block;
}

  #brands-pro-main{
  display: none;
}

  .grid-container-procd {
    grid-template-columns: repeat(3, 1fr);
    margin-left: 5vw;
    height: 85vh;
    width: 90vw;
  }

  .grid-item-procd img {
    height: 94%;
  }

  .grid-item-procd {
    padding: 0px;
  }
.overlay-procd{
  bottom: 0;
  height: 20%;
}
  .text-intro-dtl {
    font-size: 3vw;
    margin-top: 0.9vh;
  }

  .intro-brand-main {
    margin-top: 6vh;
    display: inherit;
    width: 80%;
    margin-left: 10%;
    position: revert;

  }

  .img-home-front {
    margin-top: 2vh;
    width: 90%;
    margin-left: 10%;
    display: inherit;
  }

  .img-home-front div img {
    width: 100%;
  }

  .move-color-areas-mobile {
    display: flex;
    flex-direction: column;
    color: transparent;
    width: 90%;
    margin-left: 10%;
    margin-top: 20%;

  }

  .move-color-areas-mobile div {
    background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
    -webkit-background-clip: text;

    font-size: 12vw;
    line-height: 1.1;
    font-weight: bold;
    width: fit-content;
  }

  #brand {
    font-size: 15vw;
  }

  .gradiant-color {
    font-size: 3vw;
    text-align: center;
    width: 72%;
    line-height: 1.5;
    line-break: anywhere;

  }

  .line-container {
    margin-bottom: 5vh;
    margin-top: 5vh;
  }

  .gradiant-color-texts {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    height: 100vh;
  }

  .line-container {
    width: 60vw;
    height: 5px;
  }

  .sm-en-intro1 {
    margin-bottom: 0px;
  }

  .gradient-circle {
    display: none;
  }

  .move-color-areas {
    display: none;
  }
}

.intro-pg-logo {
  height: 2.5vw;
  object-fit: cover;
}

@media (min-width: 350px) and (max-width: 1023px) {


  .move-color-areas-mobile div {
    font-size: 10vw;
  }

  .sm-en-intro1,
  .sm-cn-intro1 {
    font-size: 3vw;
  }

  .sm-en-intro1 p {
    font-size: 3.5vw;
  }


  .intro-pg-logo {
    height: 1.5rem;
  }

  .text-intro-dtl-title {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .text-intro-dtl {
    width: 100%;
  }

}
</style>


<style>
.mycontainer1 {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: #101010;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;


}

/* *,
*::before,
*::after {

    box-sizing: border-box;

} */


body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.bran-gradiant-title {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 28rem;
  font-weight: bolder;
  display: inline-block;
  margin-top: 10%;

}

.line-container {
  width: 0;
  overflow: hidden;
  margin-top: 12vh;
  margin-bottom: 12vh;
  height: 10px;
  width: 0;
  overflow: hidden;

  border-radius: 1rem;
}


@keyframes lineAnimation {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.gradiant-color {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.3rem;

  width: 50%;
  margin-top: 1rem;
  text-align: center;
}

.gradiant-color-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-color-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-color-texts {
  color: white;
  background: linear-gradient(to left, rgb(16, 85, 189), rgb(106 157 193), rgb(38, 149, 201), rgb(141 135 45), rgb(221, 210, 57), rgb(230, 61, 32), rgb(230, 61, 32), rgb(141 26 4));
  -webkit-background-clip: text;
  color: transparent;
  font-size: 6.5rem;
  width: fit-content;
  margin-top: 1rem;
  padding: 0.5rem;
  font-weight: bolder;
}

.move-color-texts-two {
  display: flex;
}


.gradient-circle {
  position: relative;
  z-index: 888;
  overflow: visible;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: visible;
  background: linear-gradient(135deg, rgb(16, 85, 189), rgb(106 157 193), rgb(38, 149, 201), rgb(228, 218, 75), rgb(221, 210, 57), rgb(230, 61, 32), rgb(230, 61, 32), rgb(141 26 4));
}

.img-home-front {
  display: flex;
  z-index: 9999;
  position: relative;
  justify-content: flex-end;
  margin-top: 23.5vh;
}

.intro-brand-main {
  display: flex;
  position: absolute;
  top: 70vh;
  left: 7vw;
  flex-direction: column;
  align-items: flex-start;
}


.text-intro-dtl {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  font-size: 1.1vw;
  color: transparent;
  width: 60%;
}

.text-intro-dtl-title {
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  font-size: 1.5vw;
  color: transparent;
  width: 60%;
  font-weight: bold;
}

#wam-into {
  display: none;
}

#fit-into {
  /*display: none;*/
}

#fenge-into {
  display: none;
}

:root {
  --grid-font-size: 8vw;
  --grid-sec-top: 18vh;
  --grid-fir-top: 16vh;

}

.gradient-pare {
  position: relative;
  width: 50%;
  font-weight: bolder;

}

#meaningful-gr,
#meaningful-gr-gradient {
  right: 1vw;
  top: var(--grid-sec-top);
}

#create-gr,
#create-gr-gradient {
  left: -18rem;
  /* top: var(--grid-sec-top); */
}

#brand {
  font-size: 25vw;
}

#brand-gr,
#brand-gr-gradient {
  left: 3vw;
  top: var(--grid-sec-top);

}

#we-gr,
#we-gr-gradient {
  right: 19vw;
}


/* 处理颜色， 动画 we create meaningful brands */
.text-mv-gr,
.text-mv-gradient {

  position: absolute;
  font-size: var(--grid-font-size);
  width: auto;
  background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
}

.text-mv-gr {
  /*color: #808080;*/
  /* 这里可以修改为您想要的灰色 */
}

#we-gr-gradient,
#meaningful-gr-gradient {
  clip-path: inset(0 99.9% 0 0);
}

#create-gr-gradient {
  clip-path: inset(0 70.9% 0% 30%);

}

#brand-gr-gradient {
  clip-path: inset(0 60.9% 0% 40%);

}

.text-mv-gradient {
  background: linear-gradient(to left, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  /* 初始状态，文字被剪裁，无法看见 */
  transition: clip-path 1s ease-in-out;

}
</style>
<template>
  <meta name="description" content="宁波索迈视听科技有限公司官网新闻招聘somle offical website">
  <div class="mycontainer1">
    <div>
      <img class="logo-mid-img" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="">
    </div>
    <div class="nav-areas">
      <!--      <div class="one_nav">-->
      <!--        <div class="one-nav-en">-->
      <!--          <router-link to="/">Home</router-link>-->
      <!--        </div>-->
      <!--        <div class="one-nav-cn">-->
      <!--          <router-link to="/">主页</router-link>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="one_nav">
        <div class="one-nav-en">
          <router-link to="/news">NEWS</router-link>
        </div>
        <div class="one-nav-cn">
          <router-link to="/news">新闻资讯</router-link>
        </div>
      </div>
      <div class="one_nav">
        <div class="one-nav-en">
          <router-link to="/brandsinfo">BRAND</router-link>
        </div>
        <div class="one-nav-cn">
          <router-link to="/brandsinfo">旗下品牌</router-link>
        </div>
      </div>
      <div class="one_nav">
        <div class="one-nav-en">
          <router-link to="/introduction">ABOUT</router-link>
        </div>
        <div class="one-nav-cn">
          <router-link to="/introduction">关于索迈</router-link>
        </div>
      </div>

      <div class="one_nav">
        <div class="one-nav-en">
          <router-link to="/joinus">TALENT</router-link>
        </div>
        <div class="one-nav-cn">
          <router-link to="/joinus">人才发展</router-link>
        </div>
      </div>

      <div class="one_nav">
        <div class="one-nav-en">
          <router-link to="/contactus">CONTACT</router-link>
        </div>
        <div class="one-nav-cn">
          <router-link to="/contactus">联系我们</router-link>
        </div>
      </div>
    </div>
    <div class="hm-btn-nav">
      <div lang="en">To benefit the world, using our creativeness</div>
      <div>用我们的创意惠及全球</div>
    </div>


    <div class="footer-copyright-mobile">

      <div>版权所有ICP证: <a class="gov-conv" target="_blank" href="https://beian.miit.gov.cn/">浙ICP备14042697号</a>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: 'AppHome',
  data() {
    return {
      imageLink: '',
      companyName: 'Somle',
    };
  },
  created() {
    // this.fetchHomeData();
  },
  mounted() {
    document.title = '主页|SOMLE';
  },
  methods: {
    async fetchHomeData() {
      // const response = await fetch('/api/home/'); // 这是Django后端API的假设路径
      // const data = await response.json();
      // this.imageLink = data.image_link;
      // this.imageLink = 'https://cdn.pixabay.com/photo/2017/07/19/01/41/clouds-2517653_1280.jpg';
      // this.companyName = data.company_name;
    }
  }
};
</script>

<style scoped>
.mycontainer1 {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: url('https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/pc_hm.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.logo-mid-img {
  width: 13vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

a {
  color: white;
}

a:hover {
  text-decoration: none;
}

.one_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.0vw;
  padding: 2.5vw;
  transition: transform 0.3s ease; /* 这将使放大效果平滑过渡 */

}

.nav-areas {
  display: flex;
}

.one_nav:hover {
  transform: scale(1.2); /* 这将使元素在鼠标悬停时放大到150% */
}

.hm-btn-nav {
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.one-nav-en {
  font-family: "Arial" !important;
  font-weight: bold !important;
}


.footer-copyright-mobile{
  margin-bottom: -7vh;
}



@media (min-width: 300px) and (max-width: 1200px) {

.footer-copyright-mobile{
  margin-bottom: -2vh;
}

  .nav-areas {
    display: flex;
    flex-direction: column;
  }

  .logo-mid-img {
    width: 30vw;
    margin-top: 10vh;
  }

  .one_nav {
    font-size: 4vw;
    padding: 3vw;
  }

  .hm-btn-nav {
    font-size: 3vw;
  }
  .footer-copyright-mobile{
    font-size: 2vw;
  }

}


</style>
<style>
@font-face {
  font-family: 'alibabapuhui';
  src: url('https://offsitesm.oss-cn-shanghai.aliyuncs.com/AlibabaPuHuiTi-3-45-Light.ttf') format('truetype');
}

body {
  font-family: 'alibabapuhui';
  font-display: swap
}

div:lang(en) {
  font-family: "Arial" !important;
  font-display: swap
  /*font-weight: bold !important;*/

}

a:lang(en) {
  font-family: "Arial" !important;
  font-display: swap
  /*font-weight: bold !important;*/

}

p:lang(en) {
  font-family: "Arial" !important;
  font-display: swap
  /*font-weight: bold !important;*/
}

</style>


































<template>
  <div class="mycontainer1">
    <div class="news-title-area">
      <div class="news-title-en" lang="en">News</div>
      <div class="news-title-cn" lang="zh">新闻资讯</div>
    </div>
    <div class="news-cards">
      <div class="news-card" v-for="news in newsList" :key="news.front_img">
        <div class="news-card-front">
          <img class="news-card-img" :src="news.front_img">
        </div>
        <div class="news-card-back">
          <img class="news-card-img" :src="news.back_img">
        </div>
      </div>
       <div class="news-card add-news-card" v-for="news in newsList" :key="news.front_img">
        <div class="news-card-front">
          <img class="news-card-img" :src="news.front_img">
        </div>
        <div class="news-card-back">
          <img class="news-card-img" :src="news.back_img">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';


export default {
  name: "AppNews",
  data() {
    return {
      newsList: [],
      page: 1
    };
  },

  mounted() {
    document.title = '新闻|SOMLE';

    // 请求数据
    axios.get('/api/news')
        .then(response => {
          this.newsList = response.data.data.map(item => {
            return {
              front_img: 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/' + item.zmt,
              back_img: 'https://offsitesm.oss-cn-shanghai.aliyuncs.com/' + item.fmt
            };
          });
          this.$nextTick(() => {
            // 当DOM更新后添加事件侦听器
            const cards = document.querySelectorAll('.news-card');
            cards.forEach(card => {
              card.addEventListener('click', this.toggleCard);
            });
          });
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

    if (window.innerWidth >= 1920) {
      // this.executeAnLargeScreen();
    } else {
      // this.executeAnSmallScreen();
    }
  },

  beforeUnmount() {
    const cards = document.querySelectorAll('.news-card');
    cards.forEach(card => {
      card.removeEventListener('click', this.toggleCard);
    });
  },


  methods: {
    toggleCard(event) {
      const card = event.currentTarget;
      card.classList.toggle('clicked');
      if (card.classList.contains('clicked')) {
        this.pauseScrolling();
      } else {
        this.resumeScrolling();
      }
    },

    pauseScrolling() {
      const newsCards = document.querySelector('.news-cards');
      newsCards.style.animationPlayState = 'paused';
    },

    resumeScrolling() {
      const newsCards = document.querySelector('.news-cards');
      newsCards.style.animationPlayState = 'running';
    },
  }
}
</script>


<style scoped>


@font-face {
  font-family: 'alibabapuhui';
  src: url('https://offsitesm.oss-cn-shanghai.aliyuncs.com/AlibabaPuHuiTi-3-45-Light.ttf') format('truetype');
}


body:lang(zh) {
  font-family: 'alibabapuhui';
  /*src: url('@/assets/fonts/AlibabaPuHuiTi-3-45-Light.ttf') format('truetype');*/
}

body:lang(en) {
  font-family: "Arial"; /* 这里填写适合英文显示的字体 */
}

.mycontainer1 {
  width: 100vw;
  height: 100vh;
  background-color: #101010;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.news-title-area {
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end;
  padding: 3vw;
  font-weight: bolder;
}

.news-title-en {
  font-size: 6rem;
}

.news-title-cn {
  font-size: 2rem;
}

.news-card {
  position: relative;
  /* width: 100px; */
  /* height: 200px; */
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  text-align: center;
  /* perspective: 100000px; */
}

.news-card-front, .news-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 1s;
}

.news-card-front {
  z-index: 2;
  transform: rotateY(0deg);
}

.news-card-back {
  transform: rotateY(180deg);
}

.news-card.clicked .news-card-front {
  transform: rotateY(180deg);
}

.news-card.clicked .news-card-back {
  transform: rotateY(360deg);
}

.news-card-img {
  width: 16vw;
  /* height:30rem; */
  object-fit: cover
}

.news-cards {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  /*animation: slideInFromRight 2s ease-out forwards;*/

}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.news-card:nth-child(1) {
  animation-delay: 0s;
}

.news-card:nth-child(2) {
  animation-delay: 0.5s;
}

.news-card:nth-child(3) {
  animation-delay: 1s;
}

.news-card:nth-child(4) {
  animation-delay: 1.5s;
}

.news-card:nth-child(5) {
  animation-delay: 2s;
}


@keyframes infiniteScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


.news-cards {
  width: 100%; /* Or any desired width */
  /*overflow: hidden;*/
}


.news-card {
  width: 10%; /* Example width, you can set it according to your design */
  flex: 0 0 auto; /* This prevents the flex items from shrinking or growing */
}

.news-cards {
  width: 200%; /* As there are duplicate items for infinite scrolling */
  display: flex; /* If not already set */
  animation: infiniteScroll 30s linear infinite;
}

@keyframes infiniteScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


.news-cards {
  animation: scrollRight 30s linear infinite;
  animation-play-state: running;
  justify-content: flex-start
}


@media (min-width: 350px) and (max-width: 1030px) {
  .add-news-card{
    display: none;
  }

  .news-title-area {
    align-items: center;
    align-content: center;
    margin-top: 4vh;
  }

  .news-title-en {
    font-size: 15vw;
  }

  .news-title-cn {
    font-size: 5vw;
  }

  .news-card-img {
    width: 75vw;
  }


  .news-card {
    width: 100%;
    height: 60%;
  }

  .news-cards {
    animation: none;
    flex-direction: column;
    /*width: auto;*/
    align-items: center;
    height: 70vh;
    overflow-y: scroll;
    width: 100%;
  }
}


@media only screen and (min-width: 601px) and (max-width: 1030px) {
  /* 平板样式 */
  .news-title-area {
    margin-top: 0vh;

  }

  .news-title-en {
    font-size: 8vw;
  }

  .news-title-cn {
    font-size: 5vw;
  }

  .news-card-img {
    width: 42%;
  }

  .news-card {
    height: 50%;

  }

  .news-cards {

    width: 80%;
    margin-left: 10%;
  }


}


</style>
<template>
  <div class="mycontainer1">
    <div class="logo-midle">
      <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/smlogowhite.png" alt="">
    </div>

    <div class="places-areas-mob">
      <div class="sm-earth-mob">
        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-earth.png" alt="">
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈总部</p>
        <p>中国 浙江省 宁波市 江北区 康庄南路299号 索迈大厦</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 英国</p>
        <p lang="en">Fourth Floor, 3 Gower Street London WC1E 6HA</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 制造中心</p>
        <p>中国 浙江省 慈溪市 慈东滨海工业区 慈东大道918号</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 美国（乔治亚州办事处）</p>
        <p lang="en">The building located at 943 Gainsille Highway, Buford Georgia</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 美国（加州办事处）</p>
        <p lang="en">15441 Stafford Street, City of Industry,California</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 加拿大</p>
        <p lang="en">1-170 Zenway Boulevard ,Vaughan ON L4H2Y7 ,Canada</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 德国</p>
        <p lang="en">Kaution für Lagerhaus Karl-Benz-strasse 3-5,Langenfeld</p>
      </div>
      <div class="intro-texts-mob">
        <p class="gria-text">SOMLE 索迈 · 日本</p>
        <p lang="en">東京都台東区台東二丁目27番3号</p>
      </div>

    </div>

    <div class="places-areas">


      <div class="sm-zb">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <div class="lc-text-areas">
          <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈总部</p>
          <p>&nbsp;&nbsp;&nbsp;中国 浙江省 宁波市 江北区 康庄南路299号 索迈大厦</p>
        </div>

      </div>

      <div class="sm-zzzx">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 制造中心</p>
        <p>&nbsp;&nbsp;&nbsp;中国 浙江省 慈溪市 慈东滨海工业区 慈东大道918号</p>
      </div>
      <div class="sm-ytld">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <div class="lc-text-areas">
          <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 英国</p>
          <p lang="en">&nbsp;&nbsp;&nbsp;Fourth Floor, 3 Gower Street London WC1E 6HA</p>

        </div>

      </div>
      <div class="sm-hemz">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 美国（乔治亚州办事处）</p>
        <p lang="en">&nbsp;&nbsp;&nbsp;The building located at 943 Gainsille Highway, Buford Georgia</p>
      </div>
      <div class="sm-jzbsc">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 美国（加州办事处）</p>
        <p lang="en">&nbsp;&nbsp;&nbsp;15441 Stafford Street, City of Industry,California </p>
      </div>
      <div class="sm-jnd">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 加拿大</p>
        <p lang="en">&nbsp;&nbsp;&nbsp;1-170 Zenway Boulevard ,Vaughan ON L4H2Y7 ,Canada</p>
      </div>
      <div class="sm-dg">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 德国</p>
        <p lang="en">&nbsp;&nbsp;&nbsp;Kaution für Lagerhaus Karl-Benz-strasse 3-5,Langenfeld</p>

      </div>
      <div class="sm-yg">
        <img class="sm-flag-top" src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-flag.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;SOMLE 索迈 · 日本</p>
        <p lang="en">&nbsp;&nbsp;&nbsp;東京都台東区台東二丁目27番3号 </p>
      </div>
      <div class="sm-earth">
        <img src="https://offsitesm.oss-cn-shanghai.aliyuncs.com/imgs/color-earth.png" alt="">
        <div class="bgdz-add-par">
          <div class="bgdz-add">办公地址</div>
          <div class="bgdz-add">Office Address</div>
        </div>
      </div>

    </div>
    <div class="contact-info">
      <div class="mail-info">
        <div class="main-contact">
          联系邮件E-MAIL
        </div>
        <div class="">
          市场合作<a href="mailto:market@somle.com">market@somle.com </a>
        </div>
        <div class="">
          媒体事务<a href="mailto:pr@somle.com">pr@somle.com </a>
        </div>
        <div class="">
          人才招聘<a href="mailto:hr@somle.com">hr@somle.com </a>
        </div>
      </div>
      <div class="mail-info">
        <div class="main-contact">
          客户服务Service
        </div>
        <div class="">
          <a href="tel:+86400888888">+86 400 888 888</a>
        </div>
        <div class="">
          工作时间 9:00-17:30
        </div>

      </div>

    </div>
  </div>
</template>

<script>
//#e21307,#fdb850 ,#55d08f,#2b5eaf
export default {
  name: "ContactUs",
  components: {},
  data() {
    return {}
  },
  mounted() {
    document.title ='联系我们|SOMLE'
  },
  methods: {},

}
</script>

<style scoped>
.mycontainer1 {
  width: 100vw;
  height: 100vh;
  background-color: #101010;
  overflow-x: hidden;


}

.places-areas-mob {
  display: none;
}

body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;

}

p {
  font-size: 0.8vw;

}

a {
  color: white;
  text-decoration: underline;
}

div {
  /* border: solid; */
  color: white;
}

.logo-midle img {
  width: 10vw;
  margin-right: -3%;


}

.logo-midle {
  text-align: center;
  padding-top: 6vh;
}

.places-areas {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.3fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: 60%;
  margin-left: 10%;

}

.sm-zb {
  grid-column: 2 / span 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: 10%;
}

.sm-flag-top {
  height: 3vh;
  filter: grayscale(100%);
  animation: colorize 1s ease-in-out forwards;


}

@keyframes colorize {
  to {
    filter: grayscale(0%);
  }
}

.sm-ytld {
  grid-column: 4/ span 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: -10%;
  padding-top: 6%;
}

.sm-zzzx {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  padding-left: 50%;
}

.sm-hemz {
  grid-column: 4 / span 2;
  grid-row: 2 / span 1;
  padding-left: 4%;
  padding-top: 3%;
}

.sm-dg {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  padding-left: 45%;
}

.sm-yg {
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  padding-left: 55%;
}

.sm-jzbsc {
  grid-column: 4 / span 2;
  grid-row: 3 / span 1;
  padding-left: 5%;
  padding-top: 3%;
}

.sm-jnd {
  grid-column: 4 / span 2;
  grid-row: 4 / span 1;
}

.sm-earth {
  grid-column: 3 / span 1;
  grid-row: 2 / span 2;
  text-align: center;
}

.sm-earth img {
  width: 93%;
  text-align: center;
  filter: grayscale(100%);
  animation: colorize 1s ease-in-out forwards;
  /* height: 100%; */
}

.lc-text-areas {
  margin-right: 1rem;
}

.bgdz-add {
  text-align: center;
  width: fit-content;
  background: linear-gradient(to left, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;

}

.bgdz-add-par {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5vw;
  padding-left: 4vw;
  padding-top: 1vw;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 2vh;
  padding-left: 10vw;
  line-height: 1.8;
}

.main-contact {
  text-align: center;
  width: fit-content;
  background: linear-gradient(to left, #e21307, #fdb850, #55d08f, #2b5eaf);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.2vw;


}

.mail-info {
  padding: 2vw;
}


@media (min-width: 350px) and (max-width: 1023px) {
  p {
    font-size: 2.8vw;
  }

  .logo-midle img {

    margin-right: auto;


  }

  .sm-earth-mob {
    text-align: center;
  }

  .sm-earth-mob img {
    width: 40%;
  }

  .logo-midle img {
    width: 30%;
  }

  .mycontainer1 {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: 155vh;
    justify-content: center;
  }

  .places-areas-mob {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 4vh;
  }

  .places-areas {
    display: none;
  }


  .intro-texts-mob {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-size: 2.8vw;
    padding-top: 2.5vh;
  }

  .gria-text {
    background: linear-gradient(to right, #e21307, #fdb850, #55d08f, #2b5eaf);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3.2vw;
  }


  p {
    margin-top: 0px;
    margin-bottom: 0.6rem;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    font-size: 2.8vw;
    padding-left: 0;

  }

  .mail-info {
    /*padding: 2vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .main-contact {
    font-size: 3.2vw;
    margin-bottom: 1.5vh;

  }
}


</style>
<template>
  <div id="app">
    <AppHeader />
    <router-view />
<!--    <AppFooter v-if="!isHomePage" />-->
    <AppFooter />

  </div>
</template>

<script>
import AppHeader from './components/Header.vue'
import AppFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/';  // 返回true如果当前路由是主页
    }
  }
};
</script>
